













import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  
  @Prop() title !: string
  @Prop() content !: string
  @Prop() list !: string[]

}
